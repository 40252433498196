import {
  ContactInfoSchema,
  DiscoveryPageSchema,
  DisruptionInfoSchema,
  HelpPageSchema,
  PageSlug,
  PageType,
  ReleasesInfoSchema,
  ServicePageBanner,
  ServicePageSchema,
  ServicePageWidget,
  UploadingGuidePageSchema,
  getFields,
} from "@stg-oneportal/axs-buttercms-library"
import buttercms from "buttercms"

export type CreateButterCmsServiceArgs = {
  token: string
  defaultLocale: string
  fetchPreview: boolean
}

export const createButterCmsService = (args: CreateButterCmsServiceArgs) => {
  const { token, defaultLocale, fetchPreview } = args

  const butter = buttercms(token)

  // Try to retrieve the page with the most prefered locale
  const retrieveByPreferredLocale = async (type: PageType, slug: PageSlug, preferredLocales: string[]) => {
    let response: buttercms.Response | undefined

    const extendedPreferredLocales = [...preferredLocales, defaultLocale]

    // Try all locales in preferred order, stop trying upon having an hit
    for (const locale of extendedPreferredLocales) {
      try {
        const res = await butter.page.retrieve(type, slug, { locale, preview: fetchPreview ? 1 : 0 })
        response = res
        break
      } catch (err) {}
    }

    return response
  }

  const getUploadingGuidePage = async (preferredLocales: string[]) => {
    try {
      const response = await retrieveByPreferredLocale(PageType.None, PageSlug.UploadingGuide, preferredLocales)
      if (!response) return

      const data = getFields(response?.data.data as UploadingGuidePageSchema)

      return {
        ...data,
        tabs: data.tabs.map((tab) => figureOutWhichSectionHasBottomBorder(getFields(tab))),
      }
    } catch (err) {}
  }

  const getHelpPage = async (slug: PageSlug, preferredLocales: string[]) => {
    const response = await retrieveByPreferredLocale(PageType.Help, slug, preferredLocales)
    const page = response?.data.data as HelpPageSchema

    if (!page) return
    return page
  }

  const getDiscoveryPage = async (slug: PageSlug, preferredLocales: string[]) => {
    const response = await retrieveByPreferredLocale(PageType.Discovery, slug, preferredLocales)
    const page = response?.data.data as DiscoveryPageSchema

    if (!page) return
    return page
  }

  const getContactInfo = async (preferredLocales: string[] = []) => {
    const response = await retrieveByPreferredLocale(PageType.Custom, PageSlug.ContactInfo, preferredLocales)
    const page = response?.data.data as ContactInfoSchema

    if (!page) return
    return page
  }

  const getReleasesPage = async (slug: PageSlug, preferredLocales: string[] = []) => {
    const response = await retrieveByPreferredLocale(PageType.Releases, slug, preferredLocales)
    const page = response?.data.data as ReleasesInfoSchema

    if (!page) return
    return page
  }

  const getDisruptionPage = async (slug: PageSlug, preferredLocales: string[] = []) => {
    const response = await retrieveByPreferredLocale(PageType.Disruption, slug, preferredLocales)
    const page = response?.data.data as DisruptionInfoSchema

    if (!page) return
    return page
  }

  // SERVICE PAGES --------------------------------------------------------------------------------

  // Service page banner

  const getServicePageBanner = async (slug: PageSlug, preferredLocales: string[]) => {
    const response = await retrieveByPreferredLocale(PageType.ServicePage, slug, preferredLocales)
    const page = response?.data.data as ServicePageSchema

    if (!page) return

    const {
      title,
      long_description: description,
      service_page_action_buttons,
      picture,
      service_page_steps,
    } = page.fields

    return {
      slug: page.slug,
      title,
      description,
      actionButtons: service_page_action_buttons.map(getFields),
      steps: service_page_steps.map(getFields),
      picture,
    } as ServicePageBanner
  }

  // Service page widget

  const getServicePageWidget = async (
    slug: PageSlug,
    preferredLocales: string[]
  ): Promise<undefined | ServicePageWidget> => {
    try {
      const response = await retrieveByPreferredLocale(PageType.ServicePage, slug, preferredLocales)
      const page = response?.data.data as ServicePageSchema

      if (!page) return
      const { title, picture, short_description: description, buttons } = page.fields

      return {
        title,
        picture,
        description,
        buttons: buttons.map(getFields),
      }
    } catch (err) {}
  }

  return {
    getUploadingGuidePage,
    getDiscoveryPage,
    getHelpPage,
    getServicePageBanner,
    getServicePageWidget,
    getContactInfo,
    getReleasesPage,
    getDisruptionPage,
  }
}

type Tab = {
  title: string
  document_description: { description: string; icon: string; section_title: string; sub_section_title: string }[]
}
const figureOutWhichSectionHasBottomBorder = (tab: Tab) => {
  return {
    ...tab,
    document_description: tab.document_description.map((section, index, allSections) => {
      return {
        ...section,
        bottomBorder: index < allSections.length - 1 && !allSections[index + 1].section_title,
      }
    }),
  }
}
