import { PageSlug } from "@stg-oneportal/axs-buttercms-library"

export type OnePortalLinks = ReturnType<typeof getOnePortalAppLinks>

// hide links inside a factory to prevent global imports
export const getOnePortalAppLinks = () => {
  return {
    home: () => "/",
    logout: () => "/logout",
    login: () => "/login",
    notAuthorized: () => "/not-authorized",
    help: () => "/help",
    discovery: (pageSlug: PageSlug = ":pageSlug" as PageSlug) => `/discovery/${pageSlug}`,
    customerReleases: () => "/customer-releases",
  }
}
